<template>
  <div name="register-modal" class="register-modal" transition="pop-out" width="100%" height="100%">
    <div id="registerView" class="section register-view">
      <div class="btn-modal-close" @click.prevent="$router.push('/');state.passwordResetStep=null">X</div>

      <!-- register/login select view -->
      <div v-if="compCurrentStep=='register-login'" class="container register-login">
        <h2>Bitte registriere Dich zuerst in deiner<br><span>Community #DIE34ER!!</span></h2>
        <p>Registriere dich für #die34er und erhalte jede Menge Vorteile. Du bist bereits dabei? Dann melde dich mit Namen und E&#8209;Mail&#8209;Adresse an.</p>
        <div class="btn-wrapper">
          <!-- <a class="btn green" href="#" @click.prevent="state.showRegisterForm = true">Kostenfrei registrieren</a> -->
          <a class="btn green" href="#" @click.prevent="handleRegisterView()">Registrieren</a>
          <a class="btn white" href="#" @click.prevent="state.modalCurrentStep='login'">Anmelden</a>
        </div>
      </div>

      <!-- register view -->
      <div v-if="compCurrentStep=='register'" class="container">
        <h2 v-if="currentPage < 3">Kostenfrei<br><span>Registrieren</span></h2>
        <div class="pagination-wrapper" v-if="currentPage < 3">
          <i 
            class="icon arrow-left" 
            v-if="currentPage > 1"
            @click="currentPage--"
          ></i>
          <span class="current-page">{{currentPage}}</span>
          <span>/2</span>
        </div>
        <div class="step-wrapper" v-if="currentPage < 3">
          <div class="step-1" v-if="currentPage == 1 || !isMobile">
            <div class="form-group" :class="{ 'form-group--error': $v.state.newMemberCredentials.salutation.$error }">
              <div class="aselect form__input" :data-value="customSelect.value" :data-list="customSelect.list" :class="{ active : customSelect.visible }">
                <div class="selector" @click="toggle(); $v.state.newMemberCredentials.salutation.$touch()" v-click-outside="closeSelectBox" tabindex="0">
                  <div class="label">
                    <span style="font-size: 1.125rem;line-height: 1.625rem;">{{ customSelect.value }}</span>
                  </div>
                <div class="arrow" :class="{ expanded : customSelect.visible }"></div>
                  <div :class="{ hidden : !customSelect.visible, visible : customSelect.visible }">
                    <ul>
                      <li :class="{ current : item === customSelect.value }" v-for="item in customSelect.list" @click="select(item)" :key="item">{{ item }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="error" v-if="!$v.state.newMemberCredentials.salutation.required">Field is required</div>
            </div>
            <!-- <input type="text" placeholder="Anrede" v-model="salutation"/> -->
            <div class="form-group" :class="{ 'form-group--error': $v.state.newMemberCredentials.firstname.$error }">
              <input 
                type="text" 
                class="form__input" 
                placeholder="Vorname*" 
                name="firstname"
                v-model.trim="$v.state.newMemberCredentials.firstname.$model"
                @blur="$v.state.newMemberCredentials.firstname.$touch()"
                autocomplete="off"
                tabindex="1"
              />
              <div class="error" v-if="!$v.state.newMemberCredentials.firstname.required">Field is required</div>
            </div>
            <div class="form-group" :class="{ 'form-group--error': $v.state.newMemberCredentials.lastname.$error }">
              <input 
                type="text" 
                class="form__input" 
                placeholder="Name*" 
                name="lastname"
                v-model.trim="$v.state.newMemberCredentials.lastname.$model"
                @blur="$v.state.newMemberCredentials.lastname.$touch()"
                autocomplete="off"
                tabindex="2"
              />
              <div class="error" v-if="!$v.state.newMemberCredentials.lastname.required">Field is required</div>
            </div>
            <div class="form-group" :class="{ 'form-group--error': $v.state.newMemberCredentials.company.$error }">
              <input 
                type="text" 
                class="form__input" 
                placeholder="Firma/Organisation" 
                name="company"
                v-model.trim="$v.state.newMemberCredentials.company.$model"
                @blur="$v.state.newMemberCredentials.company.$touch()"
                autocomplete="off"
                tabindex="3"
              />
              <div class="error" v-if="!$v.state.newMemberCredentials.company.required">Field is required</div>
            </div>
            <div class="form-group" :class="{ 'form-group--error': $v.state.newMemberCredentials.email.$error }">
              <input 
                type="email" 
                class="form__input" 
                placeholder="E-Mail Adresse*" 
                name="username"
                v-model.trim="$v.state.newMemberCredentials.email.$model"
                @blur="$v.state.newMemberCredentials.email.$touch()"
                tabindex="4"
              />
              <div class="error" v-if="!$v.state.newMemberCredentials.email.required">Field is required</div>
              <div class="error" v-if="!$v.state.newMemberCredentials.email.email">Kein gültiges E-Mail-Format</div>
            </div>
            <div class="form-group" :class="{ 'form-group--error': $v.state.newMemberCredentials.phone.$error }">
              <input 
                type="tel" 
                class="form__input" 
                placeholder="Telefonnummer*" 
                name="phone"
                v-model.trim="$v.state.newMemberCredentials.phone.$model"
                @blur="$v.state.newMemberCredentials.phone.$touch()"
                autocomplete="off"
                tabindex="5"
              />
              <div class="error" v-if="!$v.state.newMemberCredentials.phone.required">Field is required</div>
            </div>
            <div class="form-group" :class="{ 'form-group--error': $v.state.newMemberCredentials.password.$error }">
              <input 
                type="password" 
                class="form__input" 
                placeholder="Passwort*" 
                name="password"
                v-model.trim="$v.state.newMemberCredentials.password.$model"
                @blur="$v.state.newMemberCredentials.password.$touch()"
                autocomplete="new-password"
                tabindex="6"
              />
              <div class="error" v-if="!state.newMemberCredentials.password && !$v.state.newMemberCredentials.password.required">Field is required</div>
              <!-- <div class="error" v-if="!$v.state.newMemberCredentials.password.minLength && ($v.state.newMemberCredentials.password.containsUppercase && $v.state.newMemberCredentials.password.containsLowercase && $v.state.newMemberCredentials.password.containsNumber && $v.state.newMemberCredentials.password.containsSpecial)">Password must have at least {{ $v.state.newMemberCredentials.password.$params.minLength.min }} letters.</div> -->
              <div class="error" v-if="!$v.state.newMemberCredentials.password.minLength">Password must have at least {{ $v.state.newMemberCredentials.password.$params.minLength.min }} letters.</div>
              <!-- <div class="error" v-if="state.newMemberCredentials.password && (!$v.state.newMemberCredentials.password.containsUppercase || !$v.state.newMemberCredentials.password.containsLowercase || !$v.state.newMemberCredentials.password.containsNumber || !$v.state.newMemberCredentials.password.containsSpecial)">
                <span>Password must have at least </span>
                <span v-if="!$v.state.newMemberCredentials.password.containsUppercase">1x uppercase </span>
                <span v-if="!$v.state.newMemberCredentials.password.containsLowercase">1x lowercase </span>
                <span v-if="!$v.state.newMemberCredentials.password.containsNumber">1x number </span>
                <span v-if="!$v.state.newMemberCredentials.password.containsSpecial">1x special character </span>
              </div> -->
            </div>
            <div class="form-group" :class="{ 'form-group--error': $v.state.newMemberCredentials.passwordConfirm.$error }">
              <input 
                type="password" 
                class="form__input" 
                placeholder="Passwort wiederholen*" 
                name="passwordConfirm"
                v-model.trim="$v.state.newMemberCredentials.passwordConfirm.$model"
                @blur="$v.state.newMemberCredentials.passwordConfirm.$touch()"
                autocomplete="new-password"
                tabindex="7"
              />
              <div class="error" v-if="!$v.state.newMemberCredentials.passwordConfirm.required">Field is required</div>
              <div class="error" v-if="!$v.state.newMemberCredentials.passwordConfirm.sameAsPassword">Passwords must be identical.</div>
            </div>
          </div>
          <p class="hint">*Pflichtfelder</p>
          <div class="next-wrapper" v-if="currentPage == 1">
            <i class="big-icon right-arrow-circled" @click="currentPage++"></i>
            <p>Weiter zu Schritt 2</p>
          </div>

          <div class="step-2" v-if="currentPage == 2 || !isMobile">
            <div class="already-member-container">
              <!-- <app-switch v-model="state.newMemberCredentials.isAlreadyMember">Ich bin Mitglied im Bundesverband Finanzdienstleistung -AfW</app-switch> -->
              <label class="control control-checkbox">
                Ich bin Mitglied im Bundesverband Finanzdienstleistung -AfW
                  <input type="checkbox" v-model="state.newMemberCredentials.isAlreadyMember"  tabindex="8"/>
                <div class="control_indicator"></div>
              </label>
              <br>
              
            </div>
            <!--  -->
            <p>Ich arbeite unabhängig als registrierte Vermittlerin oder registrierter Vermittler (IHK) gemäß:</p>

            <div class="cb34er" :class="anyDirtyCheckboxes && !atLeastOneCheckboxChecked ? 'has-error':''">
              <label class="control control-checkbox">
                § 34c GewO
                  <input type="checkbox" v-model="state.newMemberCredentials.checkboxes.cb34c" @change="$v.state.newMemberCredentials.checkboxes.cb34c.$touch()" tabindex="9"/>
                <div class="control_indicator"></div>
              </label>
              <label class="control control-checkbox">
                § 34d GewO
                  <input type="checkbox" v-model="state.newMemberCredentials.checkboxes.cb34d" @change="$v.state.newMemberCredentials.checkboxes.cb34d.$touch()" tabindex="10"/>
                <div class="control_indicator"></div>
              </label>
              <label class="control control-checkbox">
                § 34f GewO
                  <input type="checkbox" v-model="state.newMemberCredentials.checkboxes.cb34f" @change="$v.state.newMemberCredentials.checkboxes.cb34f.$touch()" tabindex="11"/>
                <div class="control_indicator"></div>
              </label>
              <label class="control control-checkbox">
                § 34h GewO
                  <input type="checkbox" v-model="state.newMemberCredentials.checkboxes.cb34h" @change="$v.state.newMemberCredentials.checkboxes.cb34h.$touch()" tabindex="12"/>
                <div class="control_indicator"></div>
              </label>
              <label class="control control-checkbox">
                § 34i GewO
                  <input type="checkbox" v-model="state.newMemberCredentials.checkboxes.cb34i" @change="$v.state.newMemberCredentials.checkboxes.cb34i.$touch()" tabindex="13"/>
                <div class="control_indicator"></div>
              </label>
            </div>
            <div class="checkbox-error error-message" v-if="anyDirtyCheckboxes && !atLeastOneCheckboxChecked">Select at least one option.</div>

            <div class="switch-container">
              <div class="form-group" :class="{ 'form-group--error': ($v.state.newMemberCredentials.switches.tou.$error && state.newMemberCredentials.switches.hasWaited) }">
                <app-switch v-model.trim="$v.state.newMemberCredentials.switches.tou.$model" @change="$v.state.newMemberCredentials.switches.tou.$touch()">Ja, ich stimme den <a href="https://www.bundesverband-finanzdienstleistung.de/nutzungsbedingungen/" target="_blank">Nutzungsbedingungen</a> zu.</app-switch>
                <div class="error" v-if="!$v.state.newMemberCredentials.switches.tou.sameAs">Field is required</div>
              </div>

              <!-- <app-switch v-model="state.newMemberCredentials.switches.marketing">Ja, ich erteile dem AfW die Erlaubnis zur<br class="desktop-only"> Information und Kontaktaufnahme.</app-switch> -->
              
              <div class="form-group" :class="{ 'form-group--error': ($v.state.newMemberCredentials.switches.marketing.$error && state.newMemberCredentials.switches.hasWaited) }">
                <app-switch v-model="$v.state.newMemberCredentials.switches.marketing.$model">Ja, ich erteile dem AfW die Erlaubnis zur<br class="desktop-only"> Information und Kontaktaufnahme.</app-switch>
                <div class="error" v-if="!$v.state.newMemberCredentials.switches.marketing.sameAs">Field is required</div>
              </div>
              
              <div class="form-group" :class="{ 'form-group--error': ($v.state.newMemberCredentials.switches.privacy.$error && state.newMemberCredentials.switches.hasWaited) }">
                <app-switch v-model.trim="$v.state.newMemberCredentials.switches.privacy.$model">Ja, ich stimme den <br class="desktop-only"> <a href="https://www.bundesverband-finanzdienstleistung.de/datenschutzerklaerung/" target="_blank">Datenschutzbestimmungen</a> zu.</app-switch>
                <div class="error" v-if="!$v.state.newMemberCredentials.switches.privacy.sameAs">Field is required</div>
              </div>
              <!-- <app-switch v-model="switches.marketing">Ja, ich erteile dem AfW die Erlaubnis zur<br class="desktop-only"> Information und Kontaktaufnahme.</app-switch>
              <app-switch v-model="switches.privacy">Ja, ich stimme den<br class="desktop-only"> Datenschutzbestimmungen zu.</app-switch> -->
            </div>

            <p v-if="duplicateEmailError" class="mobile-only duplicate-mail-error">Du hast dich bereits registriert. <a href="#" @click.prevent="state.modalCurrentStep='login'">Klicke hier</a>, um dich einzuloggen</p>
            <div class="btn-wrapper mobile-only" @click="registerUser">
              <a class="btn green" href="#willkommenClub">Kostenfrei registrieren</a>
            </div>
          </div>
        </div>
        <p v-if="duplicateEmailError" class="desktop-only duplicate-mail-error">Du hast dich bereits registriert. <a href="#" @click.prevent="state.modalCurrentStep='login'">Klicke hier</a>, um dich einzuloggen</p>
        <div class="btn-wrapper desktop-only" v-if="currentPage != 3" @click="registerUser">
          <a class="btn green" href="#willkommenClub">Kostenfrei registrieren</a>
        </div>

        <!-- <div class="step-3" v-if="currentPage == 3">
          <img src="@/assets/img/paper_send.png" alt="">
          <h2>Dein Antrag<br/><span>wurde gesendet.</span></h2>
          <p>Sobald wir deine Angaben geprüft haben, werden wir uns schnellstmöglich mit dir in Verbindung setzen.</p>
          <div class="recommendation-wrapper">
            <p class="big-text"><strong>In der Zwischenzeit:</strong></p>
            <p>Überleg doch mal, welche 34er Du kennst und mit an Bord holen möchtest!</p>
            <div class="btn-wrapper">
              <a class="btn white" href="#" @click.prevent="toggleModal()">Seite empfehlen</a>
            </div>
          </div>
          <a href="#" @click="state.showRegisterForm = false">Zurück zur Startseite</a>
        </div> -->
      </div>

      <!-- register check mail view -->
      <div v-if="compCurrentStep=='checkMail'" class="container">
        <div class="check-mail-wrapper">
          <h2>Du hast es fast geschafft.<br><span>Jetzt nur noch Deine E&#8209;Mail bestätigen.</span></h2>
          <p class="w-50">Bitte schau in dein E-Mail-Postfach, ob du Post von uns bekommen hast. Dann einfach den Bestätigungslink klicken und schon kannst du an unseren Aktionen und Events teilnehmen.</p>
          <div class="btn-wrapper">
            <a class="btn green" href="#" @click.prevent="$router.push('/');">OK</a>
          </div>
        </div>
      </div>

      <!-- register success view -->
      <div v-if="compCurrentStep=='register-success'" class="container">
        <div class="success-wrapper">
          <h2><span class="white">Glückwunsch</span><br>{{compGreetings}}</h2>
          <p class="w-50">Du bist jetzt registriertes Mitglied der #DIE34ER. Ab sofort kannst du mit deinen Anmeldedaten auf alle Highlights der Community zugreifen. Dazu musst du dich lediglich anmelden.</p>
          <div class="login-form">
            <div class="form-group" :class="{ 'form-group--error': $v.state.loginCredentials.email.$error }">
              <input 
                type="text" 
                class="form__input" 
                placeholder="E-Mail Adresse" 
                v-model.trim="$v.state.loginCredentials.email.$model"
                @blur="$v.state.loginCredentials.email.$touch()"
                autocomplete="off"
              />
              <div class="error" v-if="!$v.state.loginCredentials.email.required">Field is required</div>
              <div class="error" v-if="!$v.state.loginCredentials.email.email">Kein gültiges E-Mail-Format</div>
            </div>
            <div class="form-group" :class="{ 'form-group--error': $v.state.loginCredentials.password.$error }">
              <input 
                type="password" 
                class="form__input" 
                placeholder="Passwort" 
                v-model.trim="$v.state.loginCredentials.password.$model"
                @blur="$v.state.loginCredentials.password.$touch()"
                autocomplete="off"
              />
              <div class="error" v-if="!$v.state.loginCredentials.password.required">Field is required</div>
            </div>
            <div class="link-wrapper">
              <a href="#" class="text-link" @click.prevent="state.modalCurrentStep='password-reset-request'">Passwort vergessen? Hier klicken!</a>
            </div>
            <div class="btn-wrapper">
              <a class="btn green" href="#" @click.prevent="loginUser">Einloggen und zu den Highlights</a>
            </div>
          </div>
          <div class="share-wrapper-small">
            <p class="bigger-text">#DIE34ER empfehlen</p>
            <p>Dir gefällt deine neue Community? Dann teile die Seite und hol andere Unabhängige mit an Bord. Denn gemeinsam sind wir noch stärker.</p>
            <div class="link-wrapper">
              <ShareNetwork
                v-for="network in state.sharing.networks"
                :network="network.network"
                :key="network.network"
                :url="state.sharing.sharingData.url"
                :title="state.sharing.sharingData.title"
                :description="state.sharing.sharingData.description"
                :quote="state.sharing.sharingData.quote"
                :hashtags="state.sharing.sharingData.hashtags"
                :twitterUser="state.sharing.sharingData.twitterUser"
                @click.native="$store.trackEvent('Share', network.name)"
              >
                <i :class="network.icon"></i>
                <span>{{ network.name }}</span>
              </ShareNetwork>
            </div>
          </div>
        </div>
      </div>

      <!-- login view -->
      <div class="container" v-if="compCurrentStep=='login'">
        <div class="login-wrapper">
          <div class="login-form">
            <h2>Anmelden</h2>
            <div class="form-group" :class="{ 'form-group--error': $v.state.loginCredentials.email.$error }">
              <input 
                type="text" 
                class="form__input" 
                placeholder="E-Mail Adresse" 
                v-model.trim="$v.state.loginCredentials.email.$model"
                @blur="$v.state.loginCredentials.email.$touch();$v.state.loginCredentials.email.$touched = true"
                autocomplete="off"
              />
              <div class="error" v-if="!$v.state.loginCredentials.email.required">Field is required</div>
              <div class="error" v-if="!$v.state.loginCredentials.email.email && $v.state.loginCredentials.email.$dirty && $v.state.loginCredentials.email.$touched">Kein gültiges E-Mail-Format</div>
            </div>
            <div class="form-group" :class="{ 'form-group--error': $v.state.loginCredentials.password.$error }">
              <input 
                type="password" 
                class="form__input" 
                placeholder="Passwort" 
                v-model.trim="$v.state.loginCredentials.password.$model"
                @blur="$v.state.loginCredentials.password.$touch()"
                autocomplete="off"
              />
              <div class="error" v-if="!$v.state.loginCredentials.password.required">Field is required</div>
            </div>
            <div class="form-group link-wrapper">
              <a href="#" class="text-link" @click.prevent="state.modalCurrentStep='password-reset-request'">Passwort vergessen? Hier klicken!</a>
            </div>
            <p class="error err-credentials" v-if="state.loginCredentials.error != null">{{state.loginCredentials.error}}</p>
            <div class="btn-wrapper">
              <a class="btn white" href="#" @click.prevent="loginUser">Anmelden</a>
            </div>
            <div class="form-group link-wrapper">
              <a href="#" class="text-link" @click.prevent="state.modalCurrentStep='register'">Neu hier? Kostenlos registrieren!</a>
            </div>
          </div>
        </div>
      </div>

      <!-- password-reset-request view -->
      <div class="container" v-if="compCurrentStep=='password-reset-request'">
        <div class="password-reset-request-wrapper">
          <div class="password-reset-request-form">
            <h2>Passwort vergessen?</h2>
            <div class="form-group" :class="{ 'form-group--error': $v.state.loginCredentials.email.$error }">
              <input 
                type="text" 
                class="form__input" 
                placeholder="E-Mail Adresse" 
                v-model.trim="$v.state.loginCredentials.email.$model"
                @blur="$v.state.loginCredentials.email.$touch()"
                autocomplete="off"
              />
              <div class="error" v-if="!$v.state.loginCredentials.email.required">Field is required</div>
              <div class="error" v-if="!$v.state.loginCredentials.email.email">Kein gültiges E-Mail-Format</div>
            </div>
            <div v-if="state.passwordResetStep=='reset-request-sent'" class="form-group">
              <p>Wir haben dir eine E-Mail mit einem Link zum Zurücksetzen deines Passworts geschickt. Bitte klicke auf den Link in der E-Mail.</p>
            </div>
            <div v-if="state.passwordResetStep=='reset-request-sent-error'" class="form-group">
              <p>Leider ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.</p>
            </div>
            <div class="btn-wrapper">
              <a class="btn white" href="#" @click.prevent="requestPasswordReset">Passwort zurücksetzen</a>
            </div>
          </div>
        </div>
      </div>

      <!-- password-reset view -->
      <div class="container" v-if="compCurrentStep=='password-reset'">
        <div class="password-reset-wrapper">
          <div class="password-reset-form">
            <h2>Passwort ändern</h2>
            <div class="form-group" :class="{ 'form-group--error': $v.state.loginCredentials.email.$error }">
              <input 
                type="text" 
                class="form__input" 
                placeholder="E-Mail Adresse" 
                v-model.trim="$v.state.loginCredentials.email.$model"
                @blur="$v.state.loginCredentials.email.$touch()"
                autocomplete="off"
                disabled
              />
              <div class="error" v-if="!$v.state.loginCredentials.email.required">Field is required</div>
              <div class="error" v-if="!$v.state.loginCredentials.email.email">Kein gültiges E-Mail-Format</div>
            </div>
            <div class="form-group" :class="{ 'form-group--error': $v.state.loginCredentials.password.$error }">
              <input 
                type="password" 
                class="form__input" 
                placeholder="Passwort" 
                v-model.trim="$v.state.loginCredentials.password.$model"
                @blur="$v.state.loginCredentials.password.$touch()"
                autocomplete="new-password"
              />
              <div class="error" v-if="!$v.state.loginCredentials.password.required">Field is required</div>
            </div>
            <!-- <div class="form-group" :class="{ 'form-group--error': $v.state.loginCredentials.password_confirmation.$error }">
              <input 
                type="password" 
                class="form__input" 
                placeholder="Passwort wiederholen" 
                v-model.trim="$v.state.loginCredentials.password_confirmation.$model"
                @blur="$v.state.loginCredentials.password_confirmation.$touch()"
                autocomplete="new-password"
              />
              <div class="error" v-if="!$v.state.loginCredentials.password_confirmation.required">Field is required</div>
              <div class="error" v-if="!$v.state.loginCredentials.password_confirmation.confirmed">Passwörter stimmen nicht überein</div>
            </div> -->
            <div class="btn-wrapper">
              <a class="btn white" href="#" @click.prevent="resetPassword">Passwort ändern</a>
            </div>
            <div v-if="state.passwordResetStep=='reset-success'" class="form-group">
              <p>Dein Passwort wurde erfolgreich geändert.</p>
            </div>
            <div v-if="state.passwordResetStep=='reset-error'" class="form-group">
              <p>Leider ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="share-wrapper" v-if="state.sharing.isVisible" @click="toggleModal()">
        <div class="share-modal">
          <p class="bigger-text">Seite empfehlen</p>
          <ShareNetwork
            v-for="network in state.sharing.networks"
            :network="network.network"
            :key="network.network"
            :url="state.sharing.sharingData.url"
            :title="state.sharing.sharingData.title"
            :description="state.sharing.sharingData.description"
            :quote="state.sharing.sharingData.quote"
            :hashtags="state.sharing.sharingData.hashtags"
            :twitterUser="state.sharing.sharingData.twitterUser"
          >
            <i :class="network.icon"></i>
            <span>{{ network.name }}</span>
          </ShareNetwork>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Switch from './Switch'
import { required, email, sameAs, minLength, requiredUnless } from 'vuelidate/lib/validators'

export default {
  name: 'RegisterView',
  components: {
    'app-switch': Switch
  },
  data() {
    return {
      state: this.$store.state,
      currentPage: 1,
      isMobile: this.$store.isMobile(),
      loginCredentials: {
        error: null
      },
      customSelect: {
        value: 'Anrede*',
        list: ["Frau","Herr","Divers", "Keine Anrede"],
        visible: false,
      },
      duplicateEmailError: false,
      salutation: null,
      // firstname: null,
      // lastname: null,
      // company: null,
      // email: null,
      checkboxes: {
        cb34c: false,
        cb34d: false,
        cb34f: false,
        cb34h: false,
        cb34i: false,
      },
      // switches: {
      //   tou: false,
      //   marketing: false,
      //   privacy: false,
      // },
    }
  },
  validations: {
    state: {
      loginCredentials: {
        email: {
          required,
          email
        },
        password: {
          required
        }
      },
      newMemberCredentials: {
        salutation: {
          required,
        },
        firstname: {
          required,
        },
        lastname: {
          required,
        },
        company: {
          // required,
        },
        email: {
          required,
          email,
        },
        phone: {
          required,
        },
        password: {
          required,
          minLength: minLength(8),
          // containsUppercase: function(value) {
          //   return /[A-Z]/.test(value)
          // },
          // containsLowercase: function(value) {
          //   return /[a-z]/.test(value)
          // },
          // containsNumber: function(value) {
          //   return /[0-9]/.test(value)
          // },
          // containsSpecial: function(value) {
          //   return /[#?!@$%^&*-]/.test(value)
          // }
        },
        passwordConfirm: {
          required,
          sameAsPassword: sameAs('password')
        },
        checkboxes: {
          cb34c: {
            // required: requiredUnless('atLeastOneCheckboxChecked'),
            // sameAs: sameAs( () => true )
          },
          cb34d: {
            // required: requiredUnless('atLeastOneCheckboxChecked'),
            // sameAs: sameAs( () => true )
          },
          cb34f: {
            // required: requiredUnless('atLeastOneCheckboxChecked'),
            // sameAs: sameAs( () => true )
          },
          cb34h: {
            // required: requiredUnless('atLeastOneCheckboxChecked'),
            // sameAs: sameAs( () => true )
          },
          cb34i: {
            // required: requiredUnless('atLeastOneCheckboxChecked'),
            // sameAs: sameAs( () => true )
          },
        },
        switches: {
          tou: {
            required,
            // sameAs: sameAs ( function (val) {
            //   console.warn(val.tou);
            //   console.warn(this.$v);
            // } )
            sameAs: sameAs( () => true )
          },
          privacy: {
            required,
            sameAs: sameAs( () => true )
          },
          marketing: {
            required,
            sameAs: sameAs( () => true )
          },
        },
      },
    }
  },
  mounted() {
    // this.show();
    // window.scrollTo(0,0);
    setTimeout(() => {
      this.$v.$reset(); 
    }, 100);
    switch (this.$route.params.regview) {
      case 'login':
        this.state.modalCurrentStep = 'login';
        break;
      case 'success':
        this.state.modalCurrentStep = 'register-success';
        break;
      case 'password-reset':
        this.state.modalCurrentStep = 'password-reset';
        let _token = location.href.split('?token=').pop();
        this.state.user.passwordResetToken = _token;
        this.state.loginCredentials.email = JSON.parse(atob(_token.split(".")[1])).email;
        break;
      default:
        break;
    }
  },
  created() {
    // setTimeout(() => {
    //   this.$v.$reset();
    // }, 10);
  },
  watch: {
    'compCurrentStep': {
      handler: function(val) {
        setTimeout(() => {
          this.$v.$reset();
        }, 10);
      },
      deep: true
    }
  },
  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his children
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    },
  },
  computed: {
    sharingModal() {
      return this.state.sharing.isVisible
    },
    compCurrentStep() {
      return this.state.modalCurrentStep;
    },
    atLeastOneCheckboxChecked() {
      return this.state.newMemberCredentials.checkboxes.cb34c || this.state.newMemberCredentials.checkboxes.cb34d || this.state.newMemberCredentials.checkboxes.cb34f || this.state.newMemberCredentials.checkboxes.cb34h || this.state.newMemberCredentials.checkboxes.cb34i;
    },
    anyDirtyCheckboxes() {
      // console.warn(this.$v.state.newMemberCredentials.checkboxes.cb34c.$dirty);
      return this.$v.state.newMemberCredentials.checkboxes.cb34c.$dirty || this.$v.state.newMemberCredentials.checkboxes.cb34d.$dirty || this.$v.state.newMemberCredentials.checkboxes.cb34f.$dirty || this.$v.state.newMemberCredentials.checkboxes.cb34h.$dirty || this.$v.state.newMemberCredentials.checkboxes.cb34i.$dirty;
    },
    compGreetings() {
      //DU BIST JETZT MITGLIED IN DER COMMUNITY #DIE34ER
      let _bonus;
      let _greetingsText;
      // check if localStorage has a key 'bonus'
      if (localStorage.getItem('bonus')) {
        _bonus = localStorage.getItem('bonus');
      }else {
        _bonus = 'none';
      }
      //events news hoodie shooting
      switch (_bonus) {
        case 'events':
          _greetingsText = 'DU BIST JETZT FÜR DEN ONLINE-EVENT ANGEMELDET!';
          break;
        case 'news':
          _greetingsText = 'DU BIST JETZT FÜR DEN COMMUNITY NEWSLETTER ANGEMELDET!';
          break;
        case 'hoodie':
          _greetingsText = 'DU BIST JETZT FÜR DAS HOODIE GEWINNSPIEL ANGEMELDET!';
          break;
        case 'shooting':
          _greetingsText = 'DU BIST JETZT FÜR DAS Foto-Shooting GEWINNSPIEL ANGEMELDET!';
          break;
        default:
          _greetingsText = 'DU BIST JETZT MITGLIED IN DER COMMUNITY #DIE34ER';
          break;
      }

      return _greetingsText;
    }
  },
  methods: {
    handleRegisterView() {
      // this.$v.$reset(); 
      this.state.newMemberCredentials.switches.hasWaited = false;
      this.state.modalCurrentStep='register'
    },
    show () {
        this.$modal.show('register-modal');
    },
    hide () {
        this.$modal.hide('register-modal');
    },
    setEmail(value) {
      this.email = value
      this.$v.email.$touch()
    },
    toggle() {
      this.customSelect.visible = !this.customSelect.visible;
    },
    select(option) {
      this.customSelect.value = option;
      // this.salutation = option;
      this.state.newMemberCredentials.salutation = option;
      // this.$v.salutation.$touch()
    },
    closeSelectBox() {
      if (this.customSelect.visible) {
        this.customSelect.visible = false;
      }
    },
    toggleModal() {
      this.state.sharing.isVisible = !this.state.sharing.isVisible;
      this.state.sharing = Object.assign({}, this.state.sharing);
    },
    async loginUser() {
      await this.$http.post(this.state.apiUrl + '/auth/login', this.state.loginCredentials)
        .then(response => {
          console.log(response.data);
          this.state.user.tokens = response.data.data;
          this.state.isLoggedIn = true;
          this.state.isMobileMenuOpen = false;
          this.getUserData();
        }).catch(error => {
          if (error.response.status === 401) {
            this.state.loginCredentials.error = 'Email oder Passwort ist falsch.';
          }
          console.log(error);
        });
    },
    async getUserData() {
      await this.$http.get(this.state.apiUrl + '/users/me', {
        headers: {
          Authorization: 'Bearer ' + this.state.user.tokens.access_token
        }
      }).then(response => {
        console.log(response.data);
        this.state.user.data = response.data.data;
        this.$router.push({ name: 'Home' });
      }).catch(error => {
        console.log(error);
      });
    },
    async requestPasswordReset() {
      let _data = {
        email: this.state.loginCredentials.email,
      }
      await this.$http.post(this.state.apiUrl + '/auth/password/request', _data)
        .then(response => {
          console.log(response.data);
          this.state.passwordResetStep = 'reset-request-sent';
        }).catch(error => {
          this.state.passwordResetStep = 'reset-request-sent-error';
          console.log(error);
        }
      );
    },
    async resetPassword() {
      let _data = {
        token: this.state.user.passwordResetToken,
        password: this.state.loginCredentials.password,
      }
      await this.$http.post(this.state.apiUrl + '/auth/password/reset', _data)
        .then(response => {
          console.log(response.data);
          this.state.user.passwordResetToken = null;
          this.state.passwordResetStep = 'reset-success';
        }).catch(error => {
          console.log(error);
          this.state.user.passwordResetToken = null;
          this.state.passwordResetStep = 'reset-error';
        }
      );
    },
    async registerUser() {
      // console.warn(this.$v.state.newMemberCredentials);
      if (this.$v.state.newMemberCredentials.$invalid) {
        this.$v.$touch();
        return;
      }

      // if not at least one checkbox is checked, return
      if (!this.atLeastOneCheckboxChecked) {
        this.$v.$touch();
        return;
      }
      
      this.duplicateEmailError = false;

      let _salutation = this.customSelect.value;
      if (_salutation == 'Anrede*' || _salutation == 'Keine Anrede') {
        _salutation = 'keine_anrede';
      }else {
        _salutation = _salutation.toLowerCase();
      }


      let result = await this.$http.post(this.state.apiUrl + '/registerUser', {
        salutation: _salutation,
        first_name: this.state.newMemberCredentials.firstname,
        last_name: this.state.newMemberCredentials.lastname,
        company: this.state.newMemberCredentials.company,
        phone_number: this.state.newMemberCredentials.phone,
        email: this.state.newMemberCredentials.email,
        password: this.state.newMemberCredentials.password,
        registration_point: this.state.newMemberCredentials.registration_point,
        alreadyMember: this.state.newMemberCredentials.isAlreadyMember,
        cb_34c: this.state.newMemberCredentials.checkboxes.cb34c,
        cb_34d: this.state.newMemberCredentials.checkboxes.cb34d,
        cb_34f: this.state.newMemberCredentials.checkboxes.cb34f,
        cb_34h: this.state.newMemberCredentials.checkboxes.cb34h,
        cb_34i: this.state.newMemberCredentials.checkboxes.cb34i,
        terms_of_use: this.state.newMemberCredentials.switches.tou,
        consent_declaration: this.state.newMemberCredentials.switches.marketing,
        privacy_policy: this.state.newMemberCredentials.switches.privacy,
        // status: "draft",
        // role: "94276e2a-a5df-4ab4-86aa-c4baa2e5d153",
      }).then(response => {
        console.log(response);
        // this.currentPage = 3;
        this.state.modalCurrentStep = 'checkMail';
      }).catch(error => {
        console.log({error});
        if (error.response.data == 'Field "email" has to be unique.') {
          this.duplicateEmailError = true;
        }
      });
    },
    // async sendMail() {
    //   if (this.$v.$invalid) {
    //     this.$v.$touch();
    //     return;
    //   }
    //   // else {
    //   //   console.log('sendMail');
    //   // }
    //   // return 
    //   console.log("send Mail: " + this.sealName + " " + this.email);
    //   let result = await this.$http.post(this.state.apiUrl + '/sendCustomEmail/member', {
    //     salutation: this.customSelect.value,
    //     firstname: this.state.newMemberCredentials.firstname,
    //     lastname: this.state.newMemberCredentials.lastname,
    //     company: this.state.newMemberCredentials.company,
    //     mail: this.state.newMemberCredentials.email,
    //     paragraphD: this.checkboxes.cb34d,
    //     paragraphE: this.checkboxes.cb34e,
    //     paragraphF: this.checkboxes.cb34f,
    //     paragraphH: this.checkboxes.cb34h,
    //     paragraphI: this.checkboxes.cb34i,
    //     tou: this.state.newMemberCredentials.switches.tou,
    //     contactMe: this.state.newMemberCredentials.switches.marketing,
    //     privacy: this.state.newMemberCredentials.switches.privacy,
    //   }).then(response => {
    //     console.log(response);
    //     this.currentPage = 3;
    //   }).catch(error => {
    //     console.log(error);
    //   });
    // },
  },
}
</script>

<style scoped lang="stylus">
  .error.err-credentials {
    opacity: 1;
    font-size: 1rem;
    line-height: 1rem;
    font-style: normal;
    color: #d90000;
    text-align: center;
  }

  .register-modal 
    position: absolute;
    z-index: 100;
  .register-view
    background-color: #2e2e38;
    font-size 1rem
    min-height 100vh
    height auto
    width 100vw
    padding-top 5rem
    padding-inline 1.5rem
    padding-bottom 2.875rem

    .desktop-only {
      display none
    }
    .mobile-only {
      display block
    }

    .btn-modal-close {
      position absolute
      top 25px
      right 25px
      padding-inline 1.5rem
      color #86BD27
      font-size 2rem
      padding 1rem
      cursor pointer
      z-index 2
    }

    z-index:0;
    transform:scale(1);
    .modal-background {
      background:rgba(0,0,0,.7);
      .modal {
        animation: blowUpModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    + .content {
      z-index:1;
      animation:blowUpContent .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    &.out {
      .modal-background {
        .modal {
          animation: blowUpModalTwo .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }
      + .content {
        animation: blowUpContentTwo .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }

    .icon
      width 12px
      height 12px

      &.arrow-left
        background-image url('~@/assets/img/icon_up_green.svg')
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        margin-left -24px
        transform rotate(-90deg)
        align-self center


    h2
      // font-size 1.5625rem
      // line-height 1.5625rem
      color #fff
      font-weight 900
      text-align center
      text-transform uppercase

      span
        color #86BD27
    
    .pagination-wrapper
      display flex
      justify-content center
      padding-block .5rem
      height 24px

      span
        font-size 0.875rem
        line-height 1.25rem
        color #fff
        align-self center

        &.current-page
          color #86BD27

    .step-1
      margin-top 2.25rem

    .close-btn
      position: absolute;
      right: 20px
      top: 20px
      font-size: 1rem;
      font-weight: 900;
      color: #86BD27;
      border: 1px solid #86BD27;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

    p
      color #fff
      text-align center

      &.big-text
        font-size 1.5625rem
        line-height 1.5625rem
        font-weight 900
        text-transform uppercase

      &.green 
        color #86BD27

    .name-wrapper
      font-size 0.875rem
      line-height 1.25rem
      max-width 200px
      margin 1.375rem auto 1.875rem

    .form-group {
      margin-bottom 1.25rem
      width 360px
    }

    .aselect {
      font-size 1rem
      line-height 1.25rem
      color #DDDDE3
      background-color transparent
      border 1px solid rgba(255, 255, 255, 0.5)
      border-radius 4px
      padding 0
      width 100%
      // margin-bottom 1.25rem
      transition border-color .2s ease
      cursor pointer

      &.active {
        color #86BD27
        border 1px solid #86BD27

        .selector {
          .label span {
            color #86BD27
          }
          .arrow {
            // border-top: 10px solid #86BD27;
            background-color: #86BD27;
          }
        }
      }

      .selector {
        border: none;
        background: none;
        position: relative;
        z-index: 1;
        padding: 1.125rem

        .arrow {
          position: absolute;
          right: 0.75rem;
          top: 0.75rem;
          width: 0;
          height: 0;
          // border-left: 7px solid transparent;
          // border-right: 7px solid transparent;
          // border-top: 10px solid #fff;
          width 40px
          height 40px
          mask-image: url('~@/assets/img/icon_down_green.svg')
          mask-size: contain;
          background-color: #DDDDE3
          transform: all //rotateZ(0deg) translateY(0px);
          transition-duration: 0.3s;
          transition-timing-function: cubic-bezier(.59,1.39,.37,1.01);
        }
        .expanded {
          transform: rotateZ(180deg) translateY(2px);
        }
        .label {
          display: block;
          font-size: 1.125rem;
          line-height: 1.625rem;
          color: #fff;
          user-select: none;
        }
      }
      ul {
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-size: 1.125rem;
        line-height: 1.625rem;
        outline: 1px solid #86BD27;
        position: absolute;
        z-index: 1;
        background: #2e2e38;
        left 0
        top 100%
        border-bottom-left-radius 4px;
        border-bottom-right-radius 4px;
        box-shadow: 0 5px 10px 2px rgba(0,0,0, 0.5)
      }
      li {
        padding: 12px;
        color: #fff;
        &:hover {
          color: #fff;
          background: #86BD27;
          cursor: pointer;
        }
      }
      .current {
        // background: #86BD27;
        background: #2e2e38;
        color #86BD27
      }
      .hidden {
        // visibility: hidden;
        opacity 0
        pointer-events: none;
        transition: opacity .2s ease
        ul {
          top 0
          transition: top .05s ease
        }
      }
      .visible {
        // visibility: visible;
        opacity 1
        pointer-events: all;
        transition: opacity .2s ease
        ul {
          top 100%
          transition: top .05s ease
        }
      }
    }

    input, select, textarea
      font-size 1rem
      line-height 1.25rem
      // color #DDDDE3
      color #fff
      background-color transparent
      border 1px solid rgba(255, 255, 255, 0.5)
      border-radius 4px
      padding 1rem
      width 100%
      // margin-bottom 1.25rem
      transition border-color .2s ease
      
      &::placeholder
        color #fff
        transition color .2s ease


      &:focus
        color #86BD27
        border 1px solid #86BD27
        outline none !important
        transition border-color .5s ease
        
        &::placeholder
          color #86BD27
          transition color .5s ease

    .btn-wrapper
      text-align center
      margin-block 0.6rem
      display flex
      justify-content center

      &.desktop-only
        display none

      &.mobile-only
        display block

      .btn
        font-size 1.125rem
        font-weight 900
        line-height 1.25rem
        padding 1.125rem 3.5rem
        border-radius 100px
        color #fff
        text-decoration none
        
        &.green
          background-color #86BD27
        
        &.white
          background-color #fff
          // color #86BD27
          color #1C1C22

        &.disabled
          opacity .5
          curosr not-allowed
    
    .next-wrapper
      text-align center
      margin-top 1.125rem
      display flex
      flex-direction: column;
      align-items: center;

      .big-icon
        width 70px
        height 70px
        margin-bottom 1rem

        &.right-arrow-circled
          background-image url('~@/assets/img/icon_right_circled.svg')
          background-repeat: no-repeat;

    .container
      .login-wrapper
        width: 365px;
        margin: 0 auto;

        .link-wrapper
          text-align: right;
          .text-link
            color #fff
            font-size 0.625rem

        .btn
          width: 100%;
          border-radius: 4px;
          font-size: 1.125rem;
          font-weight: 500;
          padding 1.25rem 1.75rem;

          &.white
            background-color #fff
            color #1C1C22

      .check-mail-wrapper
        p.w-50
          width: 100%
          margin: 0 auto;
          font-size: 1.125rem;
          line-height: 1.25rem;
          margin-bottom: 5rem;

        .btn
          padding: 1.125rem 7.25rem;
          font-size: 1.125rem;

      .success-wrapper 
        h2
          color #86BD27

        p.w-50
          width: 100%
          margin: 0 auto;
          font-size: 1.125rem;
          line-height: 1.25rem;

  
    .password-reset-request-wrapper, .password-reset-wrapper
      // width: 365px;
      margin: 0 auto;

      .link-wrapper
        text-align: right;
        .text-link
          color #fff
          font-size 0.625rem

      .btn-wrapper {
        width: 100%;
        .btn {
          width: 100%;
          border-radius: 4px;
          // font-size: 1.125rem;
          font-size: 1rem;
          font-weight: 500;
          padding 1.25rem 1.75rem;

          &.white {
            background-color #fff
            color #1C1C22
          }
        }
      }

  .step-2
    padding-inline 0.75rem
    margin-top 3.5rem
    
    p
      text-align left
      font-size 1rem
      line-height 1.375rem
      color #DDDDE3
      margin-bottom 1.25rem
    
    .cb34er
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: column;
      height: 130px;
      // padding-right 1.5rem

      &.has-error {
        label {
          color #d90000
        }
      }

.checkbox-error {
  color #d90000
  font-style: italic
}

.control {
  display: block;
  position: relative;
  padding-left: 1.875rem;
  margin-bottom: 1.25rem;
  padding-top: 0.1875rem;
  cursor: pointer;
  font-size: 1.125rem;
  line-height 1.375rem
  -webkit-tap-highlight-color: transparent;
  color #DDDDE3
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display none
}
.control_indicator {
  position: absolute;
  top: 0.25rem;
  left: 0;
  height: 1.125rem;
  width: 1.125rem;
  background: #2e2e38;
  border: 1px solid #707070;
  border-radius: 3px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #2E2E38;
}

.control input:checked ~ .control_indicator {
  background: #86bd27;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #86bd27;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 5px;
  top: 1px;
  width: 0.25rem;
  height: 10px;
  border: solid #2e2e38;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}
.control-checkbox .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: #86bd27;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}


.switch-container
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top 3rem

  >.form-group, >.switch
    width 50%


.step-3
  img 
    width 140px
    height auto
    margin 1.25rem auto 2rem
    display block
    transform translateX(-0.625rem)
    margin-bottom 1.5rem
  
  h2
    margin-bottom 1.25rem
  
  p
    font-size 1rem
    color #DDDDE3
    line-height 1.375rem
    text-align center
    margin-bottom 2.5rem

  .recommendation-wrapper
    background-color #86BD27
    padding 1.375rem 1.5rem
    border-radius .5rem
    
    p
      font-size 0.875rem
      line-height 1.25rem
      color #fff
      margin-bottom 1.25rem

      strong
        font-size 1.125rem
        margin-bottom .5rem
    
    a
      margin 0
  
  a
    display block
    color #86BD27
    font-size 1rem
    line-height 1.375rem
    text-decoration underline
    text-align center
    margin-top 1.5rem

@media (min-width:801px) {
  // .register-view {

  //   input, select, textarea {
  //     font-size 1.125rem
  //     line-height 1.625rem
  //     padding 1.125rem
  //     // margin-bottom 1.375rem
  //   } 

  //   .container {
  //     // max-width 1000px
  //     width: 930px;
  //     margin 0 auto
  //     // padding-top 4.5rem
  //     padding-bottom: 5rem

  //     &.register-login {
  //       p {
  //         width: 48%
  //         margin 0 auto 2rem;
  //       }
  //       .btn-wrapper {
  //         display flex;
  //         width: 36%;
  //         flex-direction: column;
  //         margin 5rem auto 0;

  //         .btn {
  //           margin-bottom: 2rem;
  //           height: 4rem;
  //           line-height: 2rem;
  //           font-size: 1.125rem;
  //           font-weight: normal
  //           border-radius: 4px
  //         }
  //       }
  //     }

  //     h2 {
  //       font-size: 3.5rem
  //       line-height: 3.5rem
  //       margin-bottom 3.125rem
  //     }

  //     .pagination-wrapper, .next-wrapper {
  //       display: none
  //     }

  //     .step-wrapper {
  //       display flex
  //       flex-direction: column
  //       justify-content: space-between

  //       .hint {
  //         width: 100%;
  //         text-align: right;
  //         font-size: .75rem;
  //         margin-bottom: 2rem;
  //       }

  //       .step-1 {
  //         // width: 36%
  //         margin-top: 0
  //         width: 100%
  //         display: flex;
  //         flex-direction: row;
  //         flex-wrap: wrap;
  //         justify-content: space-between;
  //       }

  //       .step-2 {
  //         // width: 54%
  //         width: 100%
  //         margin-top: 0

  //         p {
  //           font-size: 1.125rem
  //           line-height: 1.625rem
  //         }

  //         .cb34er {
  //           justify-content: normal
            
  //           .control {
  //             line-height: 1.125rem
  //             padding-left: 1.8rem
  //             padding-right: 2.7rem
  //           }
  //         }
  //         // .switch-container {

  //         //   .switch {

  //         //     span {
  //         //       border 1px solid red
  //         //       font-size: 1.125rem
  //         //       line-height: 1.625rem
  //         //     }
  //         //   }
  //         // }

  //       }
  //     }
  //     .step-3 {
  //       max-width: 600px
  //       margin 0 auto

  //       p{
  //         font-size: 1.125rem
  //         line-height: 1.625rem
  //       }

  //       .recommendation-wrapper {
  //         padding: 2rem 4.5rem
  //         .big-text {
  //           font-size: 2rem
  //           line-height: 2.25rem
  //         }
  //         .btn-wrapper {
  //           margin-top: 1.5rem
  //           .btn {
  //             font-size: 1.125rem
  //             line-height: 1.875rem
  //             padding 1.125rem 6.5rem
  //           }
  //         }
  //       }
  //     }
  //     .btn-wrapper {
  //       &.desktop-only {
  //         display block
  //         margin 0 auto
  //         font-size: 1.125rem
  //         line-height: 1.75rem
  //         padding 1.125rem 3.5rem
  //         margin-top 1.5rem
  //       }
  //       &.mobile-only {
  //         display none
  //       }
  //     }
  //   }
  // }
}

// .share-wrapper 
//   position fixed
//   top 0
//   left 0
//   right 0
//   bottom 0
//   background-color rgba(0,0,0,0.5)
//   display flex
//   flex-direction column
//   justify-content center
//   z-index 99999
//   align-items center

//   .share-modal
//     background-color: #fff
//     display: flex
//     flex-direction: column
//     justify-content: center
//     align-items: center
//     padding: 1.6vw 4.2vw 2.2vw
//     border-radius: .5rem
//     width: 32.625rem
//     width: clamp(25rem, 35vw, 32.625rem)

//     @media screen and (max-width: 800px) {
//       width: 80.5vw
//       padding: 8.5vw 10vw 10.5vw
//     }
      

//     .bigger-text
//       font-size: 1.5rem
//       line-height: 1.875rem
//       color #86BD27
//       font-weight: 900
//       margin-bottom 1.25rem

//     a
//       color #053160
//       font-size: 1.25rem
//       line-height: 2.625rem
//       font-weight: 900
//       text-decoration: none
//       border-bottom 1px solid #053160
//       width: 100%
//       text-align: center

//       &:last-child {
//         border-bottom: none
//       }

.share-wrapper-small
  background-color #fff
  border-radius: .5rem
  padding: 2.3rem 1.5rem 5rem
  width: 100%
  margin 0 auto
  margin-top 2rem
  max-width: 375px

  p
    color #2E2E38
    // padding 0 3rem
    margin-bottom: 1rem
  
  .link-wrapper 
    display flex
    flex-direction column
    justify-content center
    align-items center  
    width: 50%
    margin 0 auto

  .bigger-text
    font-size: 1.5rem
    line-height: 1.875rem
    color #86BD27
    font-weight: 900
    margin-bottom 1.25rem

  a
    color #053160
    font-size: 1.25rem
    line-height: 2.625rem
    font-weight: 900
    text-decoration: none
    border-bottom 1px solid #053160
    width: 100%
    text-align: center

    &:last-child {
      border-bottom: none
    }


.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.5s;
}
.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}

.duplicate-mail-error.duplicate-mail-error, {
  font-size: 20px;
  color: #d90000;
  text-align: center;
  font-weight: 700;
  border: 1px solid #d90000;
  padding: 1rem;
  margin-top: 1rem;

  a {
    color: #dddde3;
    text-decoration: none;
    border-bottom 1px solid #dddde3;
    width: 100%;
    text-align: center;
  }
}

.register-view {

  input, select, textarea {
    font-size 1.125rem
    line-height 1.625rem
    padding 1.125rem
    // margin-bottom 1.375rem
  } 

  .form-group {
    width: 100%
  }

  .container {
    // max-width 1000px
    // width: 930px;
    width: 100%;
    margin 0 auto
    // padding-top 4.5rem
    padding-bottom: 5rem

    .login-wrapper {
      width: 100%
    }

    &.register-login {
      p {
        width: 100%
        margin 0 auto 2rem;
      }
      .btn-wrapper {
        display flex;
        width: 100%;
        flex-direction: column;
        margin 5rem auto 0;

        .btn {
          margin-bottom: 2rem;
          height: 4rem;
          line-height: 2rem;
          font-size: 1.125rem;
          font-weight: 900
          border-radius: 26px
        }
      }
    }

    h2 {
      // font-size: 3.5rem
      // line-height: 3.5rem
      margin-bottom 3.125rem
      margin-top: 2rem
    }

    .pagination-wrapper, .next-wrapper {
      display: flex
    }

    .step-wrapper {
      display flex
      flex-direction: column
      justify-content: space-between

      .hint {
        width: 100%;
        text-align: right;
        font-size: .75rem;
        margin-bottom: 2rem;
      }

      .step-1 {
        // width: 36%
        margin-top: 0
        width: 100%
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .step-2 {
        // width: 54%
        width: 100%
        margin-top: 0

        p {
          font-size: 1.125rem
          line-height: 1.625rem
        }

        .cb34er {
          justify-content: normal
          
          .control {
            line-height: 1.125rem
            // padding-left: 1.8rem
            // padding-right: 2.7rem
            padding-left: 1.5rem
            padding-right: 1.5rem
          }
        }
        // .switch-container {

        //   .switch {

        //     span {
        //       border 1px solid red
        //       font-size: 1.125rem
        //       line-height: 1.625rem
        //     }
        //   }
        // }

      }
    }
    .step-3 {
      max-width: 600px
      margin 0 auto

      p{
        font-size: 1.125rem
        line-height: 1.625rem
      }

      .recommendation-wrapper {
        padding: 2rem 4.5rem
        .big-text {
          font-size: 2rem
          line-height: 2.25rem
        }
        .btn-wrapper {
          margin-top: 1.5rem
          .btn {
            font-size: 1.125rem
            line-height: 1.875rem
            padding 1.125rem 6.5rem
          }
        }
      }
    }
    
    // .btn-wrapper {
    //   &.desktop-only {
    //     display block
    //     margin 0 auto
    //     font-size: 1.125rem
    //     line-height: 1.75rem
    //     padding 1.125rem 3.5rem
    //     margin-top 1.5rem
    //   }
    //   &.mobile-only {
    //     display none
    //   }
    // }
  }
}


.register-view {
  .white {
    color: #fff
  }
}

.success-wrapper {
  .login-form {
    margin-top 3rem
    width: 440px;
    margin-inline: auto;
    
    .form-group {
      width: 100%
      &:last-of-type {
        margin-bottom: 0
      }
    }
    .btn-wrapper {
      margin-top: 2.5rem
      margin-bottom: 3.5rem
      width: 100%
      a {
        display: block
        width: 100%
        padding-inline: 1.125rem
      }
    }
    .link-wrapper a {
      display: block
      color: #fff
      text-align: right
    }
  }
}

@media (min-width: 640px) {
  .register-view {
    .desktop-only {
      display block
    }
    .mobile-only {
      display none
    }
    .form-group {
      width: 50vw;
      margin-inline auto
    }
    .btn-wrapper {
      width: 50vw;
    }
    .container {
      h2 {
        margin-top 0;
      }
      .step-wrapper {
        .step-1 {
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          height: 420px;
          margin-top: 0;
          width: 100%;
          display: flex;
        }
        .step-2 {
          .cb34er {
            height: auto;
            flex-direction: row;
            label {
              padding-left: 1.8rem;
              padding-right: 2.7rem;
              height: 2rem;
            }
          }
          .switch-container {
            // margin-top .2rem
            .form-group {
              margin-inline 0;
              width: 50%
            }
          }
          
        }
        .step-3 {
          max-width: 600px
        }
      }
      &.register-login {
        p {
          width: 50%
        }
        .btn-wrapper {
          width: 50%;
          margin 5rem auto 0;
        }
      }
      .password-reset-request-wrapper, .password-reset-wrapper {
        width: 360px;
        .form-group {
          width: 100%;
        }
        btn-wrapper {
          width: 100%;
        }
      }
      .login-wrapper {

      }
    }
  }
}

@media (min-width: 768px) {
  .register-view {
    .container {
      .check-mail-wrapper {
        p.w-50 {
          width: 52%
        }
      }
      .success-wrapper {
        p.w-50 {
          width: 55%
          margin: 0 auto;

        }
        .share-wrapper-small {
          border-radius: 0.5rem;
          padding: 2.3rem 1.5rem 5rem;
          width: 40%;
          margin: 0 auto;
          margin-top: 2rem;
        }
      }
      &.register-login {
        p {
          width: 50%
        }
        .btn-wrapper {
          width: 38%;
          margin 5rem auto 0;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .register-view {
    .form-group {
      width: 360px;
    }
    .btn-wrapper {
      width: 360px;

      &.desktop-only {
        width: 550px;
      }
    }

    input, select, textarea {
      font-size 1.125rem
      line-height 1.625rem
      padding 1.125rem
      // margin-bottom 1.375rem
    } 

    .container {
      // max-width 1000px
      width: 930px;
      margin 0 auto
      // padding-top 4.5rem
      padding-bottom: 5rem

      .pagination-wrapper, .next-wrapper {
        display: none
      }

      .btn-wrapper {
        &.desktop-only {
          display block
          margin 0 auto
          font-size: 1.125rem
          line-height: 1.75rem
          padding 1.125rem 3.5rem
          margin-top 1.5rem
        }
        &.mobile-only {
          display none
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .register-view {
    .form-group {
      width: 360px;
    }
    .btn-wrapper {
      width: 360px;

      &.desktop-only {
        width: 550px;
      }
    }

    input, select, textarea {
      font-size 1.125rem
      line-height 1.625rem
      padding 1.125rem
      // margin-bottom 1.375rem
    } 

    .container {
      // max-width 1000px
      width: 1030px;
      margin 0 auto
      // padding-top 4.5rem
      padding-bottom: 5rem

      .pagination-wrapper, .next-wrapper {
        display: none
      }

      .btn-wrapper {
        &.desktop-only {
          display block
          margin 0 auto
          font-size: 1.125rem
          line-height: 1.75rem
          padding 1.125rem 3.5rem
          margin-top 1.5rem
        }
        &.mobile-only {
          display none
        }
      }
    }
  }
}
</style>
