<template>
  <div>
    <RegisterView />
  </div>
</template>

<script>
import RegisterView from '../components/RegisterView.vue'

export default {
  name: 'Register',
  components: {
    RegisterView,
  },
  mounted() {
    // console.log('Register mounted');
  },
  created() {
    window.scrollTo(0, 0);
    this.$store.state.newMemberCredentials.switches.hasWaited = false;
    // console.log('Register created');
  },
}
</script>

<style lang="stylus">

</style>