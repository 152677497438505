<template>
  <label class="switch">
    <input :class="classes" type="checkbox" :checked="checked" :name="name" :disabled="disabled" v-model="value">
    <span><slot></slot></span>
  </label>
</template>

<script>
  export default {
    props: {
      disabled: Boolean,
      classes: String,
      checked: Boolean,
      name: String
    },
    data() {
      return {
        value: null,
        state: this.$store.state,
      }
    },
    beforeMount() {
      this.value = this.checked
    },
    mounted() {
      setTimeout(() => {
        this.state.newMemberCredentials.switches.hasWaited = true;
      }, 100);
      this.$emit('input', this.value)
    },
    watch: {
      value(val) {
        this.$emit('input', val)
      },
      checked (val) {
        this.value = val
      }
    }
  }
</script>

<style lang="stylus">
  label.switch {
    -webkit-tap-highlight-color: transparent;

    @media (min-width:801px) {
      margin-bottom: 2.375rem
    }

    input[type="checkbox"] {
      display: none;
      &:checked {
        + span {
          &:before {
            background-color: #516831;
          }
          &:after {
            background-color: #86BD27;
            transform: translate(80%, -50%);
          }
        }
      }
      + span {
        position: relative;
        display: inline-block;
        cursor: pointer;
        font-weight: 500;
        text-align: left;
        margin: 0px;
        padding: 0px 44px;
        font-family: 'NunitoSans';
        font-size: 1rem;
        line-height: 1.375rem;
        color: #DDDDE3;
        // margin-bottom 1.25rem

        a {
          color: #DDDDE3;
          text-decoration: underline;
        }

        @media (min-width:801px) { 
          font-size: 1.125rem;
          line-height: 1.25rem;
        }

        &:before,
        &:after {
          content: '';
          cursor: pointer;
          position: absolute;
          margin: 0;
          outline: 0;
          top: 50%;
          transform: translate(0, -50%);
          transition: all 200ms ease-out;
        }
        &:before {
          left: 1px;
          width: 34px;
          height: 14px;
          background-color: #4A4A50;
          border-radius: 8px;
          top 10px
        }
        &:after {
          left: 0;
          width: 20px;
          height: 20px;
          background-color: #747474;
          border-radius: 50%;
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .14), 0 2px 2px 0 rgba(0, 0, 0, .098), 0 1px 5px 0 rgba(0, 0, 0, .084);
          top 10px
        }
      }
      &:checked + span &:after {
        transform: translate(80%, -50%);
      }
    }
  }
</style>